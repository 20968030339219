<template>
  <div
    class="col col-md-6-cart col-sm-12 table-shopping-cart container-buy__checkout pr-3"
  >
    <div class="buy">
      <h2>RESUMEN</h2>
    </div>
    <div class="container-instructors pt-2">
      <div v-if="!isLoading">
        <div v-if="!this.getCartCoupon">
          <div
            v-for="course in this.getCart"
            :key="course.id"
            class="row justify-content-between py-3 mx-0 border-top-checkout"
          >
            <div class="col-9 d-flex align-items-center pl-0">
              <img
                :src="
                  course.thumbnail_url
                    ? course.thumbnail_url
                    : 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg'
                "
                alt=""
                class="rounded"
                width="80"
              />
              <p class="mb-0 pl-3">{{ course.name }}</p>
            </div>
            <div
              class="col-3 text-right d-flex align-items-center justify-content-end pr-0"
            >
              <p class="mb-0">${{ course.price }} USD</p>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-for="course in this.getCart"
            :key="course.id"
            class="row justify-content-between py-3 mx-0 border-top-checkout"
          >
            <div class="col-9 d-flex align-items-center pl-0">
              <img
                :src="
                  course.thumbnail_url
                    ? course.thumbnail_url
                    : 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg'
                "
                alt=""
                class="rounded"
                width="80"
              />
              <p class="mb-0 pl-3">{{ course.name }}</p>
            </div>
            <div
              class="col-3 text-right d-flex align-items-center justify-content-end pr-0"
            >
              <div
                v-if="course.withDiscount"
                style="display: Flex; flex-direction: column"
              >
                <p class="mb-0">
                  <span
                    ><s>${{ course.price }} USD</s></span
                  >
                </p>
                <p class="mb-0">
                  ${{ parseFloat(course.withDiscount.toString()).toFixed(2) }} USD
                </p>
              </div>
              <p v-else class="mb-0">${{ course.price }} USD</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-between mt-4">
          <div class="col-5">
            <p>Subtotal</p>
          </div>
          <div class="col-5 d-flex justify-content-end">
            <p>${{ this.getCartTotalPrice }} USD</p>
          </div>
          <div v-if="this.getCartCoupon" class="col-5">
            <p>Descuento</p>
          </div>
          <div
            v-if="this.getCartCoupon"
            class="col-5 d-flex justify-content-end"
          >
            <p>-${{ parseInt(this.getCartDiscount) }} USD</p>
          </div>
          <div class="col-12">
            <checkout-coupon :apply-coupon="applyCoupon"></checkout-coupon>
          </div>

          <div
            v-if="this.getCartCoupon"
            class="container d-flex justify-content-end"
          >
            <div
              class="d-flex align-items-center coupon-badge justify-content-center"
            >
              <div>{{ this.getCartCoupon.code }}</div>
              <div class="ml-1" @click="removeCoupon()">
                <span
class="material-icons close"
style="font-size: 16px"
                  >close</span
                >
              </div>
            </div>
          </div>

          <hr class="w-100 mx-3 my-2" />
          <div class="col-5">
            <p>Total</p>
          </div>
          <div
            v-if="this.getCartCoupon"
            class="col-5 d-flex justify-content-end"
          >
            <p class="total">${{ this.getCartTotalWithDiscount }} USD</p>
          </div>
          <div v-else class="col-5 d-flex justify-content-end">
            <p class="total">${{ this.getCartTotalPrice }} USD</p>
          </div>
        </div>
        <div
          v-if="this.getSelectedPayment === paymentMethod.PAYPAL_METHOD"
          id="paypal-button-container"
          class="paypal-button-container"
        ></div>
        <div
          v-else-if="
            this.getSelectedPayment === paymentMethod.PAYPAL_CREDIT_CARD_METHOD
          "
        >
          <base-button
            :disabled="$paypal.isDisablePaypalButton"
            class="md-primary"
            style="width: 100%; margin: 10px 0"
            type="primary"
            :loading="$paypal.onSubmitPaypal"
            @click="onSubmitPaypal"
            >PAGAR
          </base-button>
          <button
            id="paypal-credit-card-button-container"
            style="display: none"
          ></button>
        </div>
      </div>
      <div v-else>
        <loading-panel style="z-index: 6; height: 50vh" />
      </div>

      <h5 class="font-weight-500">
        Al hacer clic en cualquier botón de pago, confirma que ha leído,
        comprende y acepta nuestros Términos y condiciones, Política de
        devoluciones y Política de privacidad.
      </h5>
    </div>

    <div class="footer-buy">
      <h3 class="font-weight-500">Compra con confianza</h3>
      <ul>
        <li>
          <i class="far fa-calendar-alt"></i>
          <span style="color: black"
            >¡Compre sin riesgos! Ofrecemos una guarantía de 7 días en todos
            nuestros productos. Satisfacción guarantizada. Sus cursos pagos
            tendrán acceso completo de por vida, para que aprendas cuando
            quieras y desde donde quieras.
          </span>
        </li>
        <li>
          <i class="fas fa-life-ring"></i>
          <span style="color: black"
            >¡Tenemos un servicio al cliente en el que puedes contar! Vamos más
            allá para mantener feliz a nuestra comunidad.</span
          >
        </li>
        <li>
          <i class="fas fa-comment-dots"></i>
          <span style="color: black"
            >Al formar parte de nuestra plataforma, tendrás la oportunidad de
            ser parte de un grupo de personas con tus mismos intereses. También
            podrás ser parte de clases en vivo gratuitas que tendrán nuestros
            miembros con distintos invitados.
          </span>
        </li>
      </ul>
    </div>

    <div style="display: none">
      <div class="col-md-7 row px-0">
        <div class="col-md-12 pr-0">
          <label for="card-number">Número de la tarjeta</label>
          <div id="card-number" class="form-control"></div>
        </div>
        <div class="col-md-6 pr-0">
          <label for="expiredDate">Expiración:</label>
          <div id="expiredDate" class="form-control"></div>
        </div>
        <div class="col-md-6 pr-0">
          <label for="cvv">CVC:</label>
          <div id="cvv" class="form-control"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LoadingPanel from '../../../../../components/LoadingPanel';
import {
  APPLY_COUPON_TO_CART,
  DELETE_CART,
  REMOVE_APPLY_COUPON_FROM_CART,
} from '../../../../../store/actions/cart';
import {
  PAYPAL_CREDIT_CARD_METHOD,
  PAYPAL_METHOD,
} from '../../../../../util/constants';
import CheckoutCoupon from './CheckoutCoupon';

export default {
  name: 'CheckoutCart',
  components: {
    LoadingPanel,
    CheckoutCoupon,
  },
  data() {
    return {
      paymentMethod: {
        PAYPAL_CREDIT_CARD_METHOD: PAYPAL_CREDIT_CARD_METHOD,
        PAYPAL_METHOD: PAYPAL_METHOD,
      },
      vm: this,
      cant: 0,
      isLoading: true,
      isOpenPaypalCreditCardDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'getCart',
      'getCouponsArray',
      'getCartTotalPrice',
      'getCartTotalWithDiscount',
      'getCartDiscount',
      'getCartCoupon',
      'getCreditCardPrimary',
      'getSelectedPayment',
    ]),
  },
  watch: {
    getCreditCardPrimary: async function (creditCard) {
      if (creditCard && creditCard.id) {
        this.$paypal.isDisablePaypalButton = false;
      }
    },
  },

  mounted() {
    this.isLoading = false;
  },
  async updated() {
    if (this.getSelectedPayment === PAYPAL_METHOD) {
      await this.$paypal.render(
        this.getCartTotalPrice,
        this.getCartDiscount,
        this.getCart,
        this.getCartCoupon,
        {
          onError: (error) => {
            this.notify('danger', error);
          },
          onCompleted: (data) => {
            this.$router
              .push({
                name: 'CompletePayment',
                params: { invoiceId: data.transaction_invoice_id },
              })
              .then(() => {
                this.$store.dispatch(DELETE_CART);
                this.$paypal.reset();
              });
          },
        },
      );
    }
  },
  methods: {
    async onSubmitPaypal() {
      this.$paypal.onSubmitPaypal = true;
      this.$paypal.creditCardRender(
        this.getCartTotalPrice,
        this.getCartDiscount,
        this.getCart,
        this.getCartCoupon,
        this.getCreditCardPrimary,
        {
          onError: (error) => {
            this.notify('danger', error);
          },
          onCompleted: (data) => {
            this.$router
              .push({
                name: 'CompletePayment',
                params: { invoiceId: data.transaction_invoice_id },
              })
              .then(() => {
                this.$store.dispatch(DELETE_CART);
                this.$paypal.reset();
              });
          },
        },
      );
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    openPaypalCreditCardDialog() {
      this.isOpenPaypalCreditCardDialog = true;
    },
    closePaypalCreditCardDialog() {
      this.isOpenPaypalCreditCardDialog = false;
    },
    applyCoupon(couponCode) {
      this.isLoading = true;
      const data = {
        code: couponCode,
        courses: this.getCart.map((cartItem) => cartItem.id),
      };

      if (couponCode) {
        this.$store
          .dispatch(APPLY_COUPON_TO_CART, data)
          .then(() => {
            this.notify('success', 'Cupon aplicado correctamente');
            this.isLoading = false;
          })
          .catch((error) => {
            this.notify('danger', error);
            this.isLoading = false;
          });
      }
    },
    removeCoupon() {
      this.isLoading = true;
      this.$store
        .dispatch(REMOVE_APPLY_COUPON_FROM_CART)
        .then(() => (this.isLoading = false));
    },
  },
};
</script>

<style scoped>
.coupon-badge {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
  width: 100px;
}
</style>
