<template>
  <md-dialog
    :md-active="this.isOpenCreditCardDialog"
    class="modalEdit__instructors dialog-cart"
  >
    <div class="w-100 position-fixed mobile-cancel">
      <span
class="material-icons close text-right"
@click="closeDialog"
        >close</span
      >
    </div>
    <div class="body-modal-instructor modal-body">
      <div class="md-dialog-title text-left">
        <h3 class="text-bold">AGREGAR UNA NUEVA TARJETA</h3>
        <h4 class="text-bold mb-3">
          Complete los detalles de su tarjeta de crédito a continuación.
        </h4>
      </div>
      <div class="form-instructor dialog-body" style="text-align: start">
        <div class="row justify-content-between mx-0 mobile-dialog-PM">
          <div class="col-md-12 col-lg-8 row px-0">
            <div class="col-md-12 pr-0 mb-2">
              <base-input
                v-model="cardForm.cardHolderName"
                label="Nombre en la tarjeta:"
                name="firstname"
                placeholder="Nombre en la tarjeta"
                type="text"
              ></base-input>
            </div>
            <div class="col-md-12 pr-0">
              <label class="form-control-label" for="address">Número:</label>
              <the-mask
                id="address"
                v-model="cardForm.number"
                :mask="['#### #### #### ####']"
                class="form-control"
                name="tarjeta"
                placeholder="Número de la tarjeta"
                type="text"
              />
            </div>
            <div class="col-md-12 d-flex px-0">
              <div class="col-md-6 pr-0">
                <div class="form-group">
                  <label class="form-control-label">
                    Expiración (Mes/Año):
                  </label>
                  <the-mask
                    id="expiration"
                    v-model="cardForm.expiredDate"
                    :mask="['##/##']"
                    class="form-control"
                    name="expiration"
                    placeholder="Mes/Año (i.e 11/24)"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-md-6 pr-0">
                <base-input
                  v-model="cardForm.cvc"
                  label="CVC:"
                  name="cvc"
                  placeholder="CVC"
                  type="text"
                ></base-input>
              </div>
            </div>
            <div class="col-md-12 pr-0">
              <base-input
                v-model="cardForm.address"
                label="Direccion:"
                name="address"
                placeholder="Direccion"
                type="text"
              ></base-input>
            </div>
            <div class="col-md-6 pr-0">
              <base-input
                v-model="cardForm.city"
                label="Ciudad:"
                name="ciudad"
                placeholder="Ciudad"
                type="text"
              ></base-input>
            </div>
            <div class="col-md-6 pr-0">
              <base-input
                v-model="cardForm.state"
                label="Estado/Provincia:"
                name="Estado"
                placeholder="Estado/Provincia (Opcional)"
                type="text"
              ></base-input>
            </div>
            <div class="col-md-6 pr-0">
              <base-input
                v-model="cardForm.postalCode"
                label="Código postal:"
                name="codigo"
                placeholder="Código postal"
                type="text"
              ></base-input>
            </div>
            <div class="col-md-6 pr-0">
              <div class="form-group">
                <label class="form-control-label"> País: </label>
                <div class="has-label">
                  <multiselect
                    v-model="cardForm.country"
                    :close-on-select="true"
                    :options="getAllCountries"
                    :searchable="true"
                    :show-labels="false"
                    class="select-danger details-country"
                    label="name"
                    placeholder="Seleccione un País"
                    track-by="id"
                  >
                      <template #option="props">
                            <div class="option__desc pt-0">
                              <span class="option__title ">{{
                                `(${props.option.code}) ${props.option.name}`
                              }}</span>
                              <span
                                v-if="
                                  props.values &&
                                  props.values.length &&
                                  !props.values.isOpen
                                "
                                class="multiselect__single"
                                >{{ props.values.length }} options
                                selected</span
                              >
                            </div>
                          </template>
                    <template #noResult>
                      <div>Sin resultados</div>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-4">
            <div class="cardPayment card-payment-center px-4 py-2 pt-2">
              <span>Aceptamos:</span>
              <div class="card-methods-pay col-md-12">
                <img
                  src="@/assets/images/paypal/paypal.svg"
                  alt=""
                  class="paypal-svg-icon method-paypal-top"
                />
              </div>
              <div class="all-card-methods-pay row">
                <div class="col-3 p-lg-1 pt-lg-4">
                  <img
                    src="@/assets/images/paypal/visa.svg"
                    alt=""
                    class="paypal-svg-icon"
                  />
                </div>
                <div class="col-3 p-lg-1 pt-lg-4">
                  <img
                    src="@/assets/images/paypal/mastercard.svg"
                    alt=""
                    class="paypal-svg-icon"
                  />
                </div>
                <div class="col-3 p-lg-1 pt-lg-4">
                  <img
                    src="@/assets/images/paypal/maestro.svg"
                    alt=""
                    class="paypal-svg-icon"
                  />
                </div>
                <div class="col-3 p-lg-1 pt-lg-4">
                  <img
                    src="@/assets/images/paypal/americanexpress.svg"
                    alt=""
                    class="aexpress-svg-icon"
                  />
                </div>
              </div>
              <!-- <img
                :src="require(`@/assets/images/logo/safe-checkout.png`)"
                alt="icon visa"
                class="pb-1"
              /> -->
            </div>
          </div>
        </div>
      </div>
      <md-dialog-actions class="modal-footer">
        <base-button
          class="md-primary w-auto"
          type="secondary"
          @click="closeDialog"
          >CANCELAR
        </base-button>
        <base-button
          :loading="isLoading"
          class="md-primary w-auto"
          type="primary"
          @click="submit"
          >{{ `${cardForm.id ? 'ACTUALIZAR TARJETA' : 'AGREGAR TARJETA'}` }}
        </base-button>
      </md-dialog-actions>
    </div>
  </md-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import BaseButton from '../../../../../components/BaseButton';
import cardValidator from 'card-validator';
import { TheMask } from 'vue-the-mask';

import {
  CREDIT_CARD_HOLDER_NAME_MESSAGE_ERROR,
  CREDIT_CARD_NUMBER_MESSAGE_ERROR,
  CREDIT_CVV_MESSAGE_ERROR,
  CREDIT_EXPIRED_DATE_MESSAGE_ERROR,
  CREDIT_CARD_CITY,
  CREDIT_CARD_COUNTRY,
  CREDIT_CARD_STATE,
  CREDIT_CARD_POSTAL_CODE,
  DEFAULT_CARD_FORM_VALUES,
} from '../../../../../util/constants';
import {
  CLOSE_CREDIT_CARD_DIALOG,
  ADD_NEW_CARD,
  UPDATE_CREDIT_CARD,
} from '../../../../../store/actions/card';

export default {
  name: 'CreateCreditCardDialog',
  components: {
    Multiselect,
    BaseButton,
    TheMask,
  },
  emits: ['success'],

  data() {
    return {
      vm: this,
      isLoading: false,
      cardForm: DEFAULT_CARD_FORM_VALUES,
    };
  },

  computed: {
    ...mapGetters([
      'getAllCountries',
      'isOpenCreditCardDialog',
      'getSelectedCreditCard',
    ]),
  },
  watch: {
    getSelectedCreditCard: function (newValue) {
      this.cardForm = newValue ? newValue : DEFAULT_CARD_FORM_VALUES;
    },
  },
  methods: {
    submit(e) {
      const data = this.cardForm;
      const errors = [];

      if (!cardValidator.number(data.number).isValid)
        errors.push(CREDIT_CARD_NUMBER_MESSAGE_ERROR);

      if (!cardValidator.cardholderName(data.cardHolderName).isValid)
        errors.push(CREDIT_CARD_HOLDER_NAME_MESSAGE_ERROR);

      if (!cardValidator.cvv(data.cvc).isValid)
        errors.push(CREDIT_CVV_MESSAGE_ERROR);

      if (!cardValidator.expirationDate(data.expiredDate).isValid)
        errors.push(CREDIT_EXPIRED_DATE_MESSAGE_ERROR);

      if (!data.city) errors.push(CREDIT_CARD_CITY);

      if (!data.state) errors.push(CREDIT_CARD_STATE);

      if (!data.country) errors.push(CREDIT_CARD_COUNTRY);

      if (!data.postalCode) errors.push(CREDIT_CARD_POSTAL_CODE);

      if (errors.length) {
        this.notify('danger', errors.join('</br>'));
        return;
      }
      this.isLoading = true;
      if (!data.id) this.createCard();
      if (data.id) this.updateCard();

      e.preventDefault();
    },

    createCard() {
      let data = this.cardForm;
      data = {
        ...data,
        country_id: this.cardForm.country.id ? this.cardForm.country.id : '',
      };

      delete data.country;
      this.$store
        .dispatch(ADD_NEW_CARD, data)
        .then(() => {
          this.closeDialog();
          this.cardForm = DEFAULT_CARD_FORM_VALUES;
          this.isLoading = false;
          this.notify(
            'success',
            'Tarjeta de credito fue agregada correctamente',
          );
          this.$emit('success');
        })
        .catch((error) => {
          this.isLoading = false;
          this.notify('danger', error);
        });
    },
    updateCard() {
      let data = this.cardForm;

      data = {
        ...data,
        country_id: this.cardForm.country.id ? this.cardForm.country.id : '',
      };

      delete data.country;
      this.$store
        .dispatch(UPDATE_CREDIT_CARD, data)
        .then(() => {
          this.closeDialog();
          this.cardForm = DEFAULT_CARD_FORM_VALUES;
          this.isLoading = false;
          this.notify(
            'success',
            'Tarjeta de credito fue actualizada correctamente',
          );
          this.$emit('success');
        })
        .catch((error) => {
          this.isLoading = false;
          this.notify('danger', error);
        });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    closeDialog() {
      this.$store.dispatch(CLOSE_CREDIT_CARD_DIALOG);
    },
  },
};
</script>

<style>
.multiselect__option--highlight {
  background: #000000 !important;
  outline: none;
  color: #fff !important;
}
.cardPayment {
  margin-top: 0px !important;
  background-color: #fff;
  width: 100%;
}
.card-payment-center {
  margin: 0px;
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.multiselect__select {
  -webkit-transition: none;
  transition: none;
  width: 50px !important;
  margin-top: 0;
  padding: 10px;
  height: 52px;
}

.card-methods-pay {
  border-radius: 1px solid #000;
  padding: 10px;
}

.paypal-svg-icon {
  width: 100px;
  height: 50px !important;
}

.aexpress-svg-icon {
  width: 50px;
  height: 50px !important;
}

.method-paypal-top {
  margin-top: 20px;
  position: absolute;
  top: -20px;
  padding: 5px;
  background-color: #ffffff;
}

.all-card-methods-pay {
  padding: 30px 10px;
  border-radius: 10px;
  border: 1px solid #989898;
  align-content: center;
}

@media (max-width: 600px) {
  .paypal-svg-icon {
    width: 90px;
    height: 40px !important;
    padding-bottom: 5px;
  }
  .aexpress-svg-icon {
    width: 40px;
    height: 40px !important;
  }
  .all-card-methods-pay {
    height: 70px;
    align-content: center;
    padding: 0px;
  }
}

@media (min-width: 900px) and (max-width: 1440px) {
  .aexpress-svg-icon {
    width: 50px !important;
    height: 35px !important;
  }
}

.multiselect {
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.multiselect--active .multiselect__select {
  -webkit-transform: none;
  transform: none;
}
.form-control::after {
  font-size: 1rem !important;
  color: black !important;
}

.valid-feedback {
  margin-top: 0px !important;
}
</style>
