<template>
  <div v-if="!this.getSelectedPayment">
    <div class="row">
      <div class="col-sm-12 h-credit-card">
        <input
          id="credit-cart"
          type="radio"
          name="payment-method"
          @click="handleClick(constants.PAYPAL_CREDIT_CARD_METHOD)"
        />
        <img
          class="icon-img-creditcard mr-2 ml-1 mb-2"
          :src="require(`@/assets/images/logo/creditcard.png`)"
          alt="credit card"
        /><span class="font-weight-500">Tarjeta de Crédito o Débito</span><br />
      </div>
      <div class="col-sm-12 h-paypal">
        <input
          id="rd"
          type="radio"
          name="payment-method"
          @click="handleClick(constants.PAYPAL_METHOD)"
        />
        <img
          class="icon-img mr-4 ml-2 mb-1"
          :src="require(`@/assets/images/logo/Paypal_Logo_icon.png`)"
          alt="paypal"
        /><span class="font-weight-500">PayPal</span>
      </div>
      <div class="col-sm-12">
        <button class="btn btn-maquillate py-2 px-3" @click="continuePayment">
          Continuar con la compra
        </button>
      </div>
    </div>
  </div>
  <!--    <paypal-credit-card-dialog :showDialog="modal.showDialog" @close="handleCloseModal" v-if="loadScript = true"/>-->
  <div v-else>
    <div class="container">
      <div
        v-if="this.getSelectedPayment === constants.PAYPAL_METHOD"
        class="row"
      >
        <div class="col-sm-12 mb-3">
          <img
            class="icon-img-selected"
            :src="require(`@/assets/images/paypal/paypal.svg`)"
            alt="paypal"
          />
        </div>
        <div class="col-sm-12 mb-3">
          Será redirigido al sitio web de PayPal para el pago después de
          confirmar su compra
        </div>
      </div>
      <div v-else class="row">
        <div class="col-sm-12 p-0">
          <loading-panel v-if="creditCardListLoading" style="height: 250px" />
          <div v-else class="d-flex flex-column my-3 justify-content-center">
            <a
              v-if="!getCreditCardList.length"
              href="#"
              class="credit-card-link mb-2"
              @click="openCreditCardDialog"
            >
              <i class="fas fa-plus-circle"></i> Agregar nueva tarjeta de
              crédito</a
            >
            <div v-else class="payments-methods">
              <div v-for="creditCard in getCreditCardList" :key="creditCard.id">
                <div @click="changeCreditCard(creditCard)">
                  <md-card
                    class="md-credit-card mb-3"
                    :class="{
                      'credit-card-active':
                        selectedCreditCard.id === creditCard.id,
                    }"
                  >
                    <md-card-header class="md-credit-card-header">
                      <md-avatar class="md-credit-card-avatar">
                        <img
                          :src="
                            require(`@/assets/images/paypal/mastercard.svg`)
                          "
                          alt="Avatar"
                        />
                      </md-avatar>

                      <div class="md-title">
                        Terminado en {{ creditCard.number.substr(-4) }}
                      </div>
                      <div class="md-subhead">
                        Expiración: {{ creditCard.expired_date }}
                      </div>
                    </md-card-header>
                  </md-card>
                </div>
              </div>
            </div>
            <router-link
              class="credit-card-link"
              to="/user/configuracion/pagos"
            >
              <i class="fas fa-pen"></i> Administrar tarjetas de crédito
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <create-credit-card-dialog @success="refetchCreditCardList" />
  </div>
</template>

<script>
import {
  PAYPAL_METHOD,
  PAYPAL_CREDIT_CARD_METHOD,
} from '../../../../../util/constants';
import { mapGetters } from 'vuex';
import CreateCreditCardDialog from '../../Layouts/components/CreateCreditCardDialog';
import {
  CHANGE_CHECKOUT_CREDIT_CARD,
  GET_CREDIT_CARD_LIST,
  OPEN_CREDIT_CARD_DIALOG,
} from '../../../../../store/actions/card';
import { CHECKOUT_SELECTED_PAYMENT } from '../../../../../store/actions/checkout';
import LoadingPanel from '../../../../../components/LoadingPanel';

export default {
  name: 'PaypalCheckout',
  components: {
    CreateCreditCardDialog,
    LoadingPanel,
  },
  data() {
    return {
      selectedCreditCard: undefined,
      vm: this,
      modal: {
        showDialog: false,
      },
      constants: {
        PAYPAL_CREDIT_CARD_METHOD: PAYPAL_CREDIT_CARD_METHOD,
        PAYPAL_METHOD: PAYPAL_METHOD,
      },
      selectedPaymentMethod: undefined,
      creditCardListLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'getCartTotalPrice',
      'getAllCountries',
      'getCart',
      'getSelectedPayment',
      'getCartTotalWithDiscount',
      'getCartCoupon',
      'getCreditCardPrimary',
      'getCreditCardList',
    ]),
  },
  watch: {
    getCreditCardList: function (creditCardList) {
      if (creditCardList.length)
        this.selectedCreditCard = creditCardList.find(
          (creditCard) => creditCard.primary,
        );
    },
  },
  methods: {
    changeCreditCard(creditCard) {
      this.selectedCreditCard = creditCard;
      this.$store.dispatch(CHANGE_CHECKOUT_CREDIT_CARD, creditCard);
    },
    openCreditCardDialog(creditCard) {
      this.$store.dispatch(OPEN_CREDIT_CARD_DIALOG, creditCard);
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    continuePayment() {
      if (
        this.selectedPaymentMethod === this.constants.PAYPAL_CREDIT_CARD_METHOD
      ) {
        this.creditCardListLoading = true;
        this.fetchCreditCardList();
      }

      this.$store.dispatch(
        CHECKOUT_SELECTED_PAYMENT,
        this.selectedPaymentMethod,
      );
    },
    handleClick(value) {
      this.selectedPaymentMethod = value;
    },
    handleCloseModal() {
      this.selectedPaymentMethod = undefined;
      this.modal.showDialog = false;
    },
    refetchCreditCardList() {
      this.creditCardListLoading = true;
      this.fetchCreditCardList();
    },
    fetchCreditCardList() {
      this.$store
        .dispatch(GET_CREDIT_CARD_LIST)
        .then(() => {
          this.creditCardListLoading = false;
        })
        .catch(() =>
          this.notify(
            'danger',
            'Tenemos problemas al obtener las tarjetas de credito',
          ),
        );
    },
  },
};
</script>

<style scoped>
.md-credit-card {
  width: 190px;
  background-color: #ff009d04;
  border-radius: 6px;
  box-shadow: none;
  cursor: pointer;
  border: 1px solid #fff;
}

.credit-card-link {
  text-align: start;
  text-decoration: none !important;
}

.md-credit-card-avatar {
  border-radius: 0;
  width: 60px;
  margin-right: 10px;
}

.md-credit-card-header {
  margin: 0;
  padding: 10px;
}

.icon-img-selected {
  width: 100px !important;
}

.h-credit-card {
  height: 50px;
}

.h-paypal {
  height: 55px;
}

.btn-maquillate {
  width: 100% !important;
}

.md-title,
.md-subhead {
  font-size: 12px !important;
}

.credit-card-active {
  border: 2px solid #a4106c;
  border-radius: 2px;
  box-shadow: none;
}
</style>
