<template>
  <div class="row">
    <div class="col-12 d-flex justify-content-end my-3">
      <a
        v-if="!getCartCoupon"
        href="#"
        class="text-decoration-none"
        style="color: #000 !important; font-size: 15px"
        @click="showField"
        >Aplicar código de descuento</a
      >
      <a
        v-else
        href="#"
        class="text-decoration-none"
        style="color: #000 !important; font-size: 15px"
        >Código de descuento</a
      >
    </div>
    <div
      v-if="showCouponField"
      class="col-12 d-flex justify-content-end mt-0 mb-3"
    >
      <input
        v-model="couponCode"
        type="text"
        class="w-50 input-coupon"
        placeholder="Código de descuento"
      />
      <button
        class="btn btn-maquillate p-2 button-coupon"
        @click="applyCoupon(couponCode)"
      >
        AGREGAR
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CheckoutCoupon',
  props: {
    applyCoupon: {
      type: Function,
      default: function () {},
    },
  },

  data() {
    return {
      showCouponField: false,
      couponCode: '',
    };
  },
  computed: {
    ...mapGetters(['getCartCoupon']),
  },
  beforeMount() {
    this.couponCode = '';
  },
  methods: {
    showField() {
      this.showCouponField = !this.showCouponField;
    },
  },
};
</script>

<style scoped></style>
