<template>
  <div class="view-home__part7 allClasses">
    <!-- <ScriptJsSDK/> -->
    <div class="container-fluid size pr-0 style-top-container mt-4">
      <h1 class="py-3">VERIFICAR</h1>
      <div class="row pl-3 style-mobile-cart">
        <div
          class="col col-md-6-cart col-sm-12 col-lg container-instructors__body table-shopping-cart pr-3 pl-0"
        >
          <div class="p-0 border-0">
            <md-steppers
              v-model="steps.CHECKOUT_FIRST_STEP"
              md-vertical
              md-linear
              class="style-labels"
            >
              <!-- eslint-disable -->
              <md-step
                :id="steps.CHECKOUT_FIRST_STEP"
                md-label="1. CUENTA"
                :md-editable="false"
                :md-done.sync="done.first"
              >
                <checkout-user-cart></checkout-user-cart>
              </md-step>
            </md-steppers>
            <md-steppers
              md-vertical
              md-linear
              class="style-labels"
              :md-active-step.sync="getActiveStep"
            >
              <template>
                <div class="edit-payment" @click="changePayment">
                  <i class="fas fa-pen" />
                </div>
                <md-step
                  :id="steps.CHECKOUT_SECOND_STEP"
                  md-label="2. SELECCIONE UN MÉTODO DE PAGO"
                  :md-editable="false"
                  class="font-weight-600"
                  :md-done.sync="done.second"
                >
                  <template>
                    <label class="w-100 details-radio">
                      <paypal-checkout />
                    </label>
                  </template>
                </md-step>
              </template>
            </md-steppers>
          </div>
        </div>
        <checkout-cart></checkout-cart>
      </div>
    </div>
    <checkout-process-payment-dialog />
  </div>
</template>

<script>
import CheckoutUserCart from './components/CheckoutUserCart';
import { mapGetters } from 'vuex';
import PaypalCheckout from '../Components/Paypal/PaypalCheckout';
import {
  CHECKOUT_FIRST_STEP,
  CHECKOUT_SECOND_STEP,
} from '../../../../util/constants';
import CheckoutCart from './components/CheckoutCart';
import {
  CHECKOUT_CHANGE_STEP,
  CHECKOUT_SELECTED_PAYMENT,
} from '../../../../store/actions/checkout';
import CheckoutProcessPaymentDialog from './components/CheckoutProcessPaymentDialog';
import { REMOVE_APPLY_COUPON_FROM_CART } from '../../../../store/actions/cart';

export default {
  name: 'CheckOut',
  components: {
    CheckoutCart,
    PaypalCheckout,
    CheckoutUserCart,
    CheckoutProcessPaymentDialog,
  },
  data() {
    return {
      steps: {
        CHECKOUT_FIRST_STEP: CHECKOUT_FIRST_STEP,
        CHECKOUT_SECOND_STEP: CHECKOUT_SECOND_STEP,
      },
      done: {
        first: true,
        second: true,
      },
    };
  },
  computed: {
    ...mapGetters(['getIsLoadingPayment', 'getActiveStep']),
  },
  // eslint-disable-next-line
  beforeDestroy() {
    this.$store.dispatch(CHECKOUT_SELECTED_PAYMENT, undefined);
    this.$store.dispatch(CHECKOUT_CHANGE_STEP, 'unselected');
    this.$store.dispatch(REMOVE_APPLY_COUPON_FROM_CART);
  },
  methods: {
    changePayment() {
      this.$store.dispatch(CHECKOUT_SELECTED_PAYMENT, undefined);
    },
  },
};
</script>
<style scoped>
.edit-payment {
  position: absolute;
  z-index: 1000;
  right: 0;
  font-size: 14px;
  color: white;
  margin-top: 18px;
  margin-right: 15px;
  cursor: pointer;
}
</style>
