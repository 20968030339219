<template>
  <!-- eslint-disable -->
  <md-dialog
    :md-active.sync="this.$paypal.processPayment"
    class="modalEdit__instructors payment-dialog"
  >
    <div class="d-flex modal-size">
      <div class="body-modal-instructor modal-body">
        <div class="md-dialog-title text-center">
          <div class="row justify-content-center mx-0 mobile-dialog-PM">
            <img
              class="pb-1"
              :src="require(`@/assets/images/checkout/income.png`)"
              alt="income"
              width="100"
              height="100"
            />
          </div>
        </div>
        <div class="form-instructor dialog-body">
          <div class="row justify-content-center mx-0 mobile-dialog-PM">
            <h1 class="text-bold" v-if="this.$paypal.stepPayment === 'process'">
              Procesando pago
            </h1>
            <h1
              class="text-bold"
              v-if="this.$paypal.stepPayment === 'authorize'"
            >
              Autorizando pago
            </h1>
            <h1
              class="text-bold"
              v-if="this.$paypal.stepPayment === 'completed'"
            >
              Pago completado
            </h1>
          </div>
        </div>
        <div class="dialog-footer pt-2">
          <ul class="mb-1 dots">
            <li :class="{ active: true }"></li>
            <li :class="{ active: this.$paypal.isAuthorize }"></li>
            <li :class="{ active: this.$paypal.isComplete }"></li>
          </ul>
          <p>
            ¿Preguntas sobre tu pago? escribe a nuestro correo electrónico
            <a href="mailto:ayuda@maquillate.com" class="email-link"
              >ayuda@maquillate.com</a
            >
          </p>
        </div>
      </div>
    </div>
  </md-dialog>
</template>

<script>
export default {
  name: 'CheckoutProcessPaymentDialog',
};
</script>

<style>
.payment-dialog {
  z-index: 9999;
}

.payment-dialog > .md-theme-default {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
}

ul.dots {
  list-style: none;
  padding: 0;
}

ul.dots > li {
  display: inline-block;
  background-color: #ababab;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 10px;
}

ul > li.active {
  background-color: #b7227e;
}

.modal-size {
  height: 100vh;
}

.email-link {
  color: #b7227e !important;
}

.md-overlay {
  background-color: transparent !important;
}
</style>
